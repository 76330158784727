import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useStylesList() {
  // Use toast
  const toast = useToast()

  const refStyleListTable = ref(null)
  const rawStyles = ref([])
  const currentQuery = router.currentRoute.query

  // Table Handlers
  const tableColumns = [
    { key: 'name', sortable: true },
    { key: 'category', sortable: true },
    { key: 'brand_name' },
    { key: 'latest_sample_request', label: 'Sample Request' },
    { key: 'status' },
    { key: 'id', sortable: true, label: 'Created' },
    { key: 'actions' },
  ]
  const perPage = ref(+currentQuery.perPage || 10)
  const totalStyles = ref(0)
  const currentPage = ref(+currentQuery.currentPage || 1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(currentQuery.searchQuery || '')
  const sortBy = ref(currentQuery.sortBy || 'id')
  const isSortDirDesc = ref(currentQuery.isSortDirDesc === 'true' || false)
  const categoryFilter = ref(currentQuery.categoryFilter || null)
  const brandFilter = ref(+currentQuery.brandFilter || null)
  const collectionFilter = ref(+currentQuery.collectionFilter || null)
  const statusFilter = ref(currentQuery.statusFilter || 'active')

  const dataMeta = computed(() => {
    const localItemsCount = refStyleListTable.value ? refStyleListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalStyles.value,
    }
  })

  const refetchData = () => {
    const query = {}
    const { hash, name } = router.currentRoute
    if (currentPage.value) {
      query.currentPage = currentPage.value
    }
    if (perPage.value) {
      query.perPage = perPage.value
    }
    if (searchQuery.value) {
      query.searchQuery = searchQuery.value
    }
    if (sortBy.value) {
      query.sortBy = sortBy.value
    }
    if (categoryFilter.value) {
      query.categoryFilter = categoryFilter.value
    }
    if (brandFilter.value) {
      query.brandFilter = brandFilter.value
    }
    if (collectionFilter.value) {
      query.collectionFilter = collectionFilter.value
    }
    if (statusFilter.value) {
      query.statusFilter = statusFilter.value
    }
    query.isSortDirDesc = isSortDirDesc.value ? 'true' : 'false'
    router.push({ name, query, hash })
    refStyleListTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  watch([searchQuery, sortBy, isSortDirDesc, categoryFilter,
    brandFilter, collectionFilter, statusFilter], () => {
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else {
      refetchData()
    }
  })

  const fetchStyles = (ctx, callback) => {
    const ordering = isSortDirDesc.value ? sortBy.value : `-${sortBy.value}`
    store
      .dispatch('app-style/fetchStyles', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        ordering,
        category: categoryFilter.value,
        brand: brandFilter.value,
        collection: collectionFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const styles = response.data.results
        callback(styles)
        rawStyles.value = styles
        totalStyles.value = response.data.count
      })
      .catch(() => {
        callback([])
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching styles',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchStyles,
    tableColumns,
    perPage,
    currentPage,
    totalStyles,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refStyleListTable,
    rawStyles,

    refetchData,

    // UI

    // Extra Filters
    categoryFilter,
    brandFilter,
    collectionFilter,
    statusFilter,
  }
}
