<template>

  <div>
    <style-list-add-new
      :is-add-new-style-sidebar-active.sync="isAddNewStyleSidebarActive"
      :draft-limit-reached="draftLimitReached"
      :category-options="categoryOptions"
      :client-options="clientOptions"
      :brand-options="brandOptions"
      :collection-options="collectionOptions"
      :client-contact-user-options="clientContactUserOptions"
      :supplier-contact-user-options="supplierContactUserOptions"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <style-list-filters
      :category-filter.sync="categoryFilter"
      :category-options="categoryOptions"
      :brand-filter.sync="brandFilter"
      :collection-filter.sync="collectionFilter"
      :brand-options="brandOptions"
      :collection-options="collectionOptions"
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <b-row>
          <b-col class="d-flex justify-content-end mb-2">
            <b-button
              pill
              size="sm"
              class="mx-1"
              :variant="viewType == 'cards' ? 'primary': 'outline-primary'"
              @click="viewType = 'cards'"
            >
              <feather-icon
                icon="FolderIcon"
                size="16"
                class="mx-1"
              />
            </b-button>
            <b-button
              pill
              size="sm"
              class="mx-1"
              :variant="viewType == 'listing' ? 'primary': 'outline-primary'"
              @click="viewType = 'listing'"
            >
              <feather-icon
                icon="ListIcon"
                size="16"
                class="mx-1"
              />
            </b-button>
          </b-col>
        </b-row>

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                variant="primary"
                @click="isAddNewStyleSidebarActive = true"
              >
                <span class="text-nowrap">Add Style</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        v-show="viewType=='listing'"
        ref="refStyleListTable"
        class="position-relative"
        :items="fetchStyles"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Ref -->
        <template #cell(ref)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.ref }}</span>
          </div>
        </template>

        <!-- Column: Name -->
        <template #cell(name)="data">
          <div class="text-nowrap">
            <h6 class="table-cell-title my-0">
              {{ data.item.name }}
            </h6>
            <h6 class="text-muted my-0">
              {{ data.item.ref }}
            </h6>
          </div>
        </template>

        <!-- Column: Category -->
        <template #cell(category)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.category }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.status }}</span>
          </div>
        </template>

        <!-- Column: Sample Request -->
        <template #cell(latest_sample_request)="data">
          <b-link
            v-if="data.item.latest_sample_request"
            :to="{ name: 'apps-sample-requests-edit',
                   params: { id: data.item.latest_sample_request.id },
                   hash: '#1',
            }"
            class="font-weight-bold d-block text-nowrap"
          >
            <div class="text-nowrap">
              <h6 class="table-cell-title my-0">
                {{ data.item.latest_sample_request.sample_type }}
              </h6>
              <h6 class="text-muted my-0">
                {{ data.item.latest_sample_request.sample_status }}
              </h6>
            </div>
          </b-link>
        </template>

        <!-- Column: Status -->
        <template #cell(brand)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.brand_name }}</span>
          </div>
        </template>

        <!-- Column: Created at -->
        <template #cell(id)="data">
          <div class="text-nowrap">
            {{ data.item.created_at }}
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-link
            :to="{ name: 'apps-styles-edit', params: { id: data.item.id }}"
          >
            <feather-icon
              :id="`style-row-${data.item.id}-edit-icon`"
              icon="EditIcon"
              size="16"
              class="mx-1"
            />
          </b-link>
        </template>
      </b-table>

      <b-container
        v-show="viewType=='cards'"
      >
        <b-row>
          <b-col
            v-for="style in rawStyles"
            :key="style.id"
            class="style-card-box"
          >
            <b-link
              :to="{
                name: 'apps-styles-edit', params: { id: style.id },
              }"
            >
              <b-card>
                <b-card-text
                  text-tag="div"
                >
                  <div
                    v-if="style.cover_image"
                    class="image-style"
                  >
                    <img :src="style.cover_image">
                  </div>
                  <div
                    v-if="!style.cover_image"
                    class="d-flex justify-content-center align-items-center bg-light noimage-style"
                  >
                    <feather-icon
                      v-if="!style.cover_image"
                      :id="`card-${style.id}-cover`"
                      icon="EyeOffIcon"
                      size="128"
                    />
                  </div>
                  <h4 class="card-title mt-2">
                    {{ style.name }}
                  </h4>
                  <h6 class="card-subtitle text-muted">
                    {{ style.ref }}
                  </h6>

                  <div
                    v-if="style.latest_sample_request"
                  >
                    <b-link
                      :to="{ name: 'apps-sample-requests-edit',
                             params: { id: style.latest_sample_request.id },
                             hash: '#1',}"
                    >
                      <div class="d-flex flex-row justify-content-start align-items-end">
                        <div>
                          <feather-icon
                            icon="BoxIcon"
                            size="16"
                          />
                        </div>
                        <div class="text-muted ml-1 align-self-end">
                          {{ style.latest_sample_request.sample_status }}
                        </div>
                      </div>
                    </b-link>
                  </div>
                </b-card-text>
              </b-card>
            </b-link>
          </b-col>
        </b-row>
      </b-container>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalStyles"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>

import {
  BCard, BCardText, BContainer, BRow, BCol, BFormInput, BButton, BTable, BPagination, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  computed, ref, onMounted, onUnmounted, watch,
} from '@vue/composition-api'
import router from '@/router'

import useStylesList from './useStylesList'
import styleStoreModule from '../styleStoreModule'
import StyleListAddNew from './StyleListAddNew.vue'
import StyleListFilters from './StylesListFilters.vue'

export default {
  components: {
    StyleListAddNew,
    StyleListFilters,

    BCard,
    BCardText,
    BContainer,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BLink,

    vSelect,
  },
  methods: {
  },
  setup(props, { root }) {
    const STYLE_APP_STORE_MODULE_NAME = 'app-style'

    // Register module
    if (!root.$store.hasModule(STYLE_APP_STORE_MODULE_NAME)) {
      root.$store.registerModule(STYLE_APP_STORE_MODULE_NAME, styleStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (root.$store.hasModule(STYLE_APP_STORE_MODULE_NAME)) {
        root.$store.unregisterModule(STYLE_APP_STORE_MODULE_NAME)
      }
    })

    const isAddNewStyleSidebarActive = ref(false)
    const draftLimitReached = ref(false)
    const { hash } = router.currentRoute
    const viewType = ref(hash ? hash.slice(1) : 'cards')
    const userRole = JSON.parse(localStorage.getItem('userData')).role

    watch([viewType], () => {
      router.replace({
        name: router.currentRoute.name,
        query: router.currentRoute.query,
        hash: `#${viewType.value}`,
      })
    })

    const categoryOptions = computed(() => {
      const options = root.$store.getters['app-style/categoryOptions']
      return options
    })
    const clientOptions = computed(() => {
      const options = root.$store.getters['app-style/clientOptions']
      return options
    })
    const brandOptions = computed(() => {
      const options = root.$store.getters['app-style/brandOptions']
      return options
    })
    const collectionOptions = computed(() => {
      const options = root.$store.getters['app-style/collectionOptions']
      return options
    })
    const statusOptions = computed(() => {
      if (userRole === 'client' || userRole === 'client-admin') {
        return [
          { label: 'Draft, Published or Ready to Sample', value: 'active' },
          { label: 'Draft', value: 'Draft' },
          { label: 'Published', value: 'Published' },
          { label: 'Ready to Sample', value: 'Ready to Sample' },
          { label: 'Archived', value: 'Archived' },
        ]
      }
      return [
        { label: 'Published or Ready to Sample', value: 'active' },
        { label: 'Published', value: 'Published' },
        { label: 'Ready to Sample', value: 'Ready to Sample' },
        { label: 'Archived', value: 'Archived' },
      ]
    })
    const clientContactUserOptions = computed(() => {
      const options = root.$store.getters['app-style/clientContactUserOptions']
      return options
    })
    const supplierContactUserOptions = computed(() => {
      const options = root.$store.getters['app-style/supplierContactUserOptions']
      return options
    })
    const {
      fetchStyles,
      tableColumns,
      perPage,
      currentPage,
      totalStyles,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refStyleListTable,
      rawStyles,
      refetchData,

      // UI

      // Extra Filters
      categoryFilter,
      brandFilter,
      collectionFilter,
      statusFilter,
    } = useStylesList()

    const optionsInit = () => {
      root.$store.dispatch('app-style/fetchFormOptions')
    }

    const fetchDraftLimitReached = () => {
      root.$store.dispatch('app-style/fetchDraftLimitReached')
        .then(response => {
          draftLimitReached.value = response.data.draft_limit_reached
        })
    }
    onMounted(optionsInit)
    onMounted(fetchDraftLimitReached)

    return {
      // Sidebar
      isAddNewStyleSidebarActive,
      draftLimitReached,
      viewType,

      fetchStyles,
      tableColumns,
      perPage,
      currentPage,
      totalStyles,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refStyleListTable,
      rawStyles,
      refetchData,

      // Filter
      categoryFilter,
      brandFilter,
      collectionFilter,
      statusFilter,

      // UI
      categoryOptions,
      clientOptions,
      brandOptions,
      collectionOptions,
      statusOptions,
      clientContactUserOptions,
      supplierContactUserOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.style-card-box {
  flex: 300px 0;
}
.image-style img {
  object-fit: cover;
  width: 100%;
  height: 280px;
  vertical-align: middle;
  border-radius: 15px;
}
.noimage-style {
  margin: 0 auto 0 auto;
  height: 280px;
  border-radius: 15px;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
