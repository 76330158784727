<template>
  <b-sidebar
    id="add-new-style-sidebar"
    :visible="isAddNewStyleSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-add-new-style-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Style
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <b-alert
        v-model="draftLimitReached"
        variant="danger"
        class="p-1"
      >
        The maximum number of Styles as DRAFT (10) has been reached.
        Please SEND some Styles for revision first.
      </b-alert>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit, invalid }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Reference -->
          <validation-provider
            #default="validationContext"
            name="Ref"
            rules="required"
          >
            <b-form-group
              label="Ref *"
              label-for="ref"
            >
              <b-form-input
                id="ref"
                v-model="styleData.ref"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name *"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="styleData.name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Category -->
          <validation-provider
            #default="validationContext"
            name="Category"
            rules="required"
          >
            <b-form-group
              label="Category *"
              label-for="category"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="styleData.category"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="categoryOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="category"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Client -->
          <validation-provider
            v-if="userRole !== 'client-admin' && userRole !== 'client'"
            #default="validationContext"
            name="Client"
            rules="required"
          >
            <b-form-group
              label="Client *"
              label-for="client"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="styleData.client"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="clientOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="client"
                @input="refreshOptions"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Brand -->
          <validation-provider
            #default="validationContext"
            name="Brand"
            rules="required"
          >
            <b-form-group
              label="Brand *"
              label-for="brand"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="styleData.brand"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="brandFilteredOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="brand"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Collection -->
          <b-form-group
            label="Collection"
            label-for="collection"
          >
            <v-select
              v-model="styleData.collection"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="collectionFilteredOptions"
              :reduce="val => val.value"
              :clearable="true"
              input-id="collection"
            />
          </b-form-group>

          <!-- Client Contact -->
          <validation-provider
            #default="validationContext"
            name="Client Contact"
          >
            <b-form-group
              label="Client Contact"
              label-for="client-contact-user"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="styleData.client_contact_user"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="clientContactUserFilteredOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="client-contact-user"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Supplier Contact -->
          <validation-provider
            #default="validationContext"
            name="Supplier Contact"
          >
            <b-form-group
              label="Supplier Contact"
              label-for="supplier-contact-user"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="styleData.supplier_contact_user"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="supplierContactUserOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="supplier-contact-user"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Cover -->
          <b-form-group
            label="Cover"
            label-for="cover"
          >
            <b-form-file
              v-model="styleData.cover"
              placeholder="Choose file or drop it here..."
              size="sm"
            />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="invalid || draftLimitReached"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BAlert, BSidebar, BForm, BFormFile, BFormGroup, BFormInput,
  BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  components: {
    BAlert,
    BSidebar,
    BForm,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewStyleSidebarActive',
    event: 'update:is-add-new-style-sidebar-active',
  },
  props: {
    isAddNewStyleSidebarActive: {
      type: Boolean,
      required: true,
    },
    draftLimitReached: {
      type: Boolean,
      required: true,
    },
    categoryOptions: {
      type: Array,
      required: true,
    },
    clientOptions: {
      type: Array,
      required: true,
    },
    brandOptions: {
      type: Array,
      required: true,
    },
    collectionOptions: {
      type: Array,
      required: true,
    },
    clientContactUserOptions: {
      type: Array,
      required: true,
    },
    supplierContactUserOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const blankStyleData = {
      ref: '',
      name: '',
      category: '',
      brand: '',
      collection: '',
      client: '',
      client_contact_user: '',
      supplier_contact_user: '',
    }
    const styleData = ref(JSON.parse(JSON.stringify(blankStyleData)))
    const userRole = JSON.parse(localStorage.getItem('userData')).role
    const brandFilteredOptions = ref([])
    const collectionFilteredOptions = ref([])
    const clientContactUserFilteredOptions = ref([])

    const resetStyleData = () => {
      styleData.value = JSON.parse(JSON.stringify(blankStyleData))
    }

    const onSubmit = () => {
      const styleFormData = new FormData()
      Object.keys(styleData.value).forEach(
        key => styleFormData.append(key, styleData.value[key]),
      )
      store.dispatch('app-style/addStyle', styleFormData)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-style-sidebar-active', false)
        })
    }

    if (userRole === 'client' || userRole === 'client-admin') {
      brandFilteredOptions.value = [
        ...props.brandOptions,
      ]
      collectionFilteredOptions.value = [
        ...props.collectionOptions,
      ]
      clientContactUserFilteredOptions.value = [
        ...props.clientContactUserOptions,
      ]
    }

    const refreshOptions = client => {
      brandFilteredOptions.value = props.brandOptions
        .filter(option => (option.client === client))
      collectionFilteredOptions.value = props.collectionOptions
        .filter(option => (option.client === client))
      clientContactUserFilteredOptions.value = props.clientContactUserOptions
        .filter(option => (option.client === client))

      styleData.value = {
        ...styleData.value,
        brand: '',
        collection: '',
        client_contact_user: '',
      }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetStyleData)

    return {
      styleData,
      userRole,
      onSubmit,

      brandFilteredOptions,
      collectionFilteredOptions,
      clientContactUserFilteredOptions,
      refreshOptions,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
